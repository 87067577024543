
import { Component, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { StaticContentType } from '../enums/static-content-type.enum';
import { StaticContent } from '../models/static-contents/static-content.interface';
import { Disclaimer as DisclaimerModel } from '../models/static-contents/disclaimer.interface';
import { translateApi } from '../utils/translate-api';
import { UsePageHeaderMixin } from '../mixins/use-page-header.mixin';

const staticContentsModule = namespace('staticContents');

@Component({
  components: {},
  filters: {
    translateApi,
  },
})
export default class Disclaimer extends Mixins(UsePageHeaderMixin) {
  @staticContentsModule.Action('fetchStaticContent')
  fetchStaticContent!: (type: StaticContentType) => Promise<StaticContent>;

  @staticContentsModule.Getter('getStaticContentByType')
  getStaticContentByType!: (type: StaticContentType) => DisclaimerModel;

  disclaimer: DisclaimerModel | null = null;

  get disclaimerContent() {
    return translateApi((this.disclaimer as DisclaimerModel).content.value, this.$i18n.locale);
  }

  get headerHtml() {
    if (this.disclaimer) {
      return `<h2 class="text-white">${translateApi(
        this.disclaimer.title.value,
        this.$i18n.locale,
      )}</h2>`;
    } else return '';
  }

  async created() {
    if (!this.getStaticContentByType(StaticContentType.Disclaimer)) {
      await this.fetchStaticContent(StaticContentType.Disclaimer);
    }
    this.disclaimer = this.getStaticContentByType(StaticContentType.Disclaimer);
  }
}
